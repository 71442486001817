body {
  margin: 0;
  font-family: Inter-Light;
  font-size: 18px; /* TODO: Change this to % */
  width: 100%;
  height: 100vh;
  /* background-image: linear-gradient(45deg, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%); */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #FAF9F6;
  background-color: #101010;
}

.header {
  font-family: Inter-Bold;
  font-size: 36px;
}

.subheader {
  font-family: Inter-ExtraBold;
  font-size: 24px;
}

.bold-text {
  font-family: Inter-Bold;
  display: inline;
}

/* Add a fade in when the website first loads */
/* Add a swipe animation when swiping between the two components */
/* Add light mode / dark mode */
