.navbar {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 30px; /* add 20px of space between top of page and navbar */
  width: 45%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-logo img {
  height: 50px;
}

.navbar-links {
  text-decoration: none; /* no text decoration */
  font-size: 20px;
}

.navbar-links a {
  color: #FCFBFC; /* white */
  text-decoration: none; /* no text decoration */
  margin-right: 20px; /* add 20px of space between links */
}
  
.navbar-links a.active {
  font-family: Inter-SemiBold; /* bold active link */
  border-bottom: 2px solid #FCFBFC;
}

@media (max-width: 768px) {
  .navbar {
    position: absolute;
    width: 75%;
  }
}