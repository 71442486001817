.About {
    text-align: left;
    padding-bottom: 10%;
}

.About .header {
    margin-bottom: 2.5%;
}

.section {
    margin-bottom: 5%;
}