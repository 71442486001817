.contact div {
    margin-bottom: 2.5%;
}

.links {
    margin-top: 5%;
    display: flex;
    justify-content: center;
    align-items: space-between;
    transform: scale(1.3);
}

.links a {
    margin: 5px;
    width: 40px;
    height: 40px;
}

.links a:hover {
    opacity: 0.5;
}

a {
    color: #0275FF;
    text-decoration: none;
}

.picture img {
    width: 85%;
}

