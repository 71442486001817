.info {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex; /* flexbox layout */
  flex-direction: row; /* horizontal layout */
  justify-content: center;
  align-items: center;
  height: 60vh;
  width: 75%;
}

.info-left {
  width: 33%; /* take up 50% of the container width */
  margin: 10%; /* add some padding for spacing */
  margin-right: 0%;

}

.info-right {
  width: 66%; /* take up 50% of the container width */
  height: 100%;
  padding-top: 5%;
  margin-left: 10%; /* add some padding for spacing */

}

@media (max-width: 768px) {
  .info {
    flex-direction: column; /* change layout to column */
    top: 100%;
    height: 170vh;
    zoom: 0.9;
  }

  .info-left {
    width: 100%; /* take up full width */
    margin: 0; /* remove padding */
    margin-bottom: 10%; /* add some padding for spacing */
  }

  .info-right {
    width: 100%; /* take up full width */
    margin: 0; /* remove padding */
  }

  .Construction {
    font-size: 24px;
  }
  
}